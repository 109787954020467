<template>
  <div class="cell-custom">
    <div class="glo-nav">告警数据</div>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="筛选条件" class="filter">
           <van-cell title="按告警类型查" :value="filter.way.value" is-link @click="filter.way.show = true"/>
           <van-cell title="按监测区域查" v-if="filter.way.code === 0" :value="filter.area.value" is-link @click="filter.area.showPicker = true"/>
           <van-cell title="按时间段查询" :value="filter.time.value" is-link @click="filter.time.show = true"/>
           <van-row class="text-center">
              <van-col span="12"><van-button type="primary" size="small" @click="resetData">重置</van-button></van-col>
              <van-col span="12"> <van-button type="primary" size="small" @click="filterData">筛选</van-button></van-col>
           </van-row>
      </van-collapse-item>
    </van-collapse>
    <div :class="'glo-content ' +(noticeShow ? 'glo-magbot' : 'glo-magbot0')">
        <div v-if="listLoading"><van-loading type="spinner" vertical>加载中</van-loading></div>
        <div v-else>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getAlarmList" :immediate-check="false">
                <div v-if="infoList.length == 0">
                    <van-empty description="数据列表为空" />
                </div>
                <div v-else>
                    <div v-for="(item,index) in infoList" :key="index" :class=" 'block ' +(item.status == '0'?'not-l':'yet-l')">
                      <van-swipe-cell :disabled="item.status == '0' ? false : true" ref="swipecell">
                        <div>
                          <div class="title" v-if="type == 0">
                              <div v-text="item.title" :class="item.status == '0' ? 'not':'yet-title'"></div>
                              <div><i :class="'fa fa-circle ' +(item.status == '0'?'not':'yet')"></i></div>
                          </div>
                          <div class="title" v-else>
                              <div v-text="item.typeName" :class="item.status == '0' ? 'not':'yet-title'"></div>
                              <div><i :class="'fa fa-circle ' +(item.status == '0'?'not':'yet')"></i></div>
                          </div>
                          <p class="inner-txt">告警内容：{{item.content}}</p>
                          <p>告警类型：{{ translationAlarmType(item.type) || '无'}}</p>
                          <p>告警级别：{{item.level || '无'}}</p>
                          <p :class="item.status == '0'?'emphasis':'emphasis-off'">告警值：{{item.value}}</p>
                          <p>正常参考值：{{item.refer || '无'}}</p>
                          <div class="footer">
                            <div>{{item.devCode}}</div>
                            <div>{{item.createTime}}</div>
                          </div>
                        </div>
                        
                      </van-swipe-cell>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
    <!-- 筛选区域 -->
    <van-popup v-model="filter.area.showPicker" round position="bottom">
        <van-picker show-toolbar :columns="filter.area.columns" @cancel="filter.area.showPicker = false" @confirm="areaConfirm" />
    </van-popup>
    <!-- 告警类型-->
    <van-action-sheet v-model="filter.way.show" :actions="filter.way.actions" @select="waySelect" />
    <!-- 筛选时间段 -->
    <van-calendar ref="time" v-model="filter.time.show" type="range" :min-date="filter.time.minDate" :max-date="filter.time.maxDate" @confirm="timeConfirm" color="#07c160"/>
    <tabbar />
  </div>
</template>
<script>
import tabbar from '@/components/bridge/NVtabbar.vue'
import moment from "moment";

export default {
  components: {
    tabbar
  },
  props: ['magbot'],
  data() {
    return {
      activeName: '',
      infoList:[],
      filter:{
        area:{
          devCode:'',
          value:'',
          showPicker:false,
          columns:[]
        },
        way:{
          code:0,
          value:'传感器模块告警',
          show:false,
          actions:[
            {name:'传感器模块告警',id:0},
            {name:'气象模块告警',id:1},
            {name:'水位模块告警',id:2},
          ]
        },
        time:{
          start:'',
          end:'',
          minDate: new Date(2018, 0, 1),
          maxDate: new Date(),
          value:'',
          show:false
        }
      },
      projectCode:'',
      pageNum:1,
      pageSize:10,
      loading:false,
      finished:false,
      listLoading:true,
      magbotVal:'',
      type:0 ,// 判断依据
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;   
      }
  },
  methods:{
    // 转译告警类型
    translationAlarmType(type){
      let v;
      switch (type){
        case '1':
          v = '位移告警'
          break;
        case '2':
          v = '挠度告警'
          break;
        case '3':
          v = '倾角告警'
          break;
        default:
            v = type; 
      }
      return v
    },
    // 筛选监测区域确认事件
    areaConfirm(v){
      this.filter.area.showPicker = false;
      this.filter.area.value = v.text;
      this.filter.area.devCode = v.devCode;
    },
    // 筛选告警类型
    waySelect(v){
      this.filter.way.show = false;
      this.filter.way.value = v.name;
      this.filter.way.code = v.id;
    },
    // 筛选时间确认事件
    timeConfirm(date) {
      const [start, end] = date;
      this.filter.time.show = false;
      this.filter.time.start = moment(start).format("yyyy-MM-DD");
      this.filter.time.end = moment(end).format("yyyy-MM-DD");
      this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
    },
    // 重置
    resetData(){
      this.filter.area.value = '';
      this.filter.way.value='传感器模块告警';
      this.filter.time.value='';
      this.filter.area.devCode = '';
      this.filter.way.code = 0;
      this.filter.time.start = '';
      this.filter.time.end = '';
      this.$refs.time.reset();
    },
    // 筛选
    filterData(){
      this.type = this.filter.way.code;
      this.listLoading = true;
      this.finished = false;
      this.activeName = '';
      this.infoList = [];
      this.pageNum = 1;
      this.getAlarmList();
    },
    // 获取筛选区域列表
    areaList(){
      this.$api.NEWBRIDGE.sensorAllData({projectCode:this.projectCode}).then( d =>{
        this.filter.area.columns = d.map( e => ({devCode:e.devCode,text:e.devName }) );
      })
    },
    // 加载告警信息列表
    getAlarmList(){
      const data = {
        projectCode:this.projectCode,
        devCode:this.filter.area.devCode,
        beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
        endTime:this.filter.time.end ? `${this.filter.time.end} 23:59:59` :void 0
      }
      if(this.filter.way.code === 0){
        // 传感器告警
        this.$api.NEWBRIDGE.bhmsWarningPage(this.pageNum,this.pageSize,data).then( d =>{
          if(Array.isArray(d)){
              this.listLoading = false;
              this.loading = false;
              this.infoList =this.infoList.concat(d);
              this.pageSize > d.length ? this.finished = true : void 0;
              this.pageNum ++;
          }
        })
      }else if(this.filter.way.code === 1){
        // 气象告警
        this.$api.NEWBRIDGE.melvesWarningPage(this.pageNum,this.pageSize,data).then( d =>{
          if(Array.isArray(d)){
              this.listLoading = false;
              this.loading = false;
              this.infoList =this.infoList.concat(d);
              this.pageSize > d.length ? this.finished = true : void 0;
              this.pageNum ++;
          }
        })
      }else if(this.filter.way.code === 2){
        // 水位告警
        this.$api.NEWBRIDGE.wlmsWarningPage(this.pageNum,this.pageSize,data).then( d =>{
          if(Array.isArray(d)){
            d.forEach( (e,i) => {
                e.status = '1'
            })
              this.listLoading = false;
              this.loading = false;
              this.infoList =this.infoList.concat(d);
              this.pageSize > d.length ? this.finished = true : void 0;
              this.pageNum ++;
          }
        })
      }
    }
  },
  computed:{
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
  mounted(){
    this.bridgeCode = window.sessionStorage.getItem('bridgeCode');
    this.projectCode = window.sessionStorage.getItem('projectCode');
    this.getAlarmList();
    this.areaList();
  }
}
</script>
<style lang="scss" scoped>
.filter{text-align: left;}
.block{
    border: 1px solid #ececec;background-color: #fff2db;text-align: left;font-size: 0.8rem;padding: 2%;border-radius: 10px;color: #353535;margin-bottom: 2%;
    p{margin: 0;padding-bottom: 2%;color: #757575;}
    .inner-txt{text-indent: -5em;margin-left: 5em;padding-top: 2%;}
    .title{
      font-size: .95rem;font-weight: bold;text-align: center;padding: 2%;border-bottom: 1px solid #fff;color: #ff6464;
      .fa{position: absolute;top: 5%;right: 2%;}
      .not{color: #44a6d6;}
      .yet{color: #aaaaaa;}
      .yet-title{color: #757575;}
    }
    .read-btn{height:100%;border-radius: 5px;margin-left: 28%;width: 72%;}
    .footer{display: flex;justify-content: space-between;border-top: 1px solid #fff;padding: 2% 0;}
    .emphasis{font-weight: bold;color: #44a6d6;}
    .emphasis-off{font-weight: bold;color: #757575;}
}
.text-center{
  text-align: center;
  button{
    padding: 0 20px;
    margin-top: 5%;
  }
}
.not-l{background-color: #c6f5e8;}
.yet-l{background-color: #ececec;}
</style>